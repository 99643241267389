import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import { LOAD_TEAMS } from "@/core/services/store/team.module";

/*import dateFilter from './core/filters/date.filter';
Vue.filter('date', dateFilter);*/

//import MockService from "@/core/mock/mock.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import * as VueGoogleMaps from "vue2-google-maps";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/logger";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// Global registration

import VCalendar from "v-calendar";
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

// product plugins
import _ from "lodash";
import Debug from "@/view/content/Debug";

// Suggestion
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
// Required dependency of bootstrap css/scss files
// Global registration
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

Vue.component("debug", Debug);

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

Vue.prototype._ = _;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB13WknTvuwOZxHZR0qdcYlmbxFQc6y5Q8",
    language: localStorage.getItem("language") || "en",
    libraries: ["places", "visualization"],
    // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

router.beforeEach((to, from, next) => {
  // Reset actions
  store.dispatch(SET_ACTIONS, null);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH), store.dispatch(LOAD_TEAMS)]).then(
    next
  );

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  if (localStorage.getItem("language") === null && to.query.l != null) {
    localStorage.setItem("language", to.query.l);
    i18n.locale = to.query.l;
  }

  if (to.query.t != null) {
    localStorage.setItem("t", to.query.t);
  }
});

const EventBus = new Vue();
Vue.prototype.$bus = EventBus;

window.$vue = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-104092740-3",
  router: router,
});

// import VueYandexMetrika from "vue-yandex-metrika";
// Vue.use(VueYandexMetrika, {
//   id: 66815338,
//   router: router,
//   env: "production",
//   debug: "false",
//   options: {
//     clickmap: true,
//     trackLinks: true,
//     accurateTrackBounce: true,
//     webvisor: true,
//     trackHash: true,
//     ecommerce: "dataLayer",
//   },
// });
